import { BaseApi } from './base'
import { currentUserDetailsResponse } from '../types'
import { useRouter } from 'vue-router'
import { AxiosError, InternalAxiosRequestConfig } from 'axios'
import { store } from '@/store'

class AuthApi extends BaseApi {
    public constructor() {
        super(process.env.VUE_APP_API_URL)
        this.instance.interceptors.response.use(this._handleResponse, this._handleError)
        this.instance.interceptors.request.use(this._handleRequest)
    }
    public getCurrentUSer = () => this.instance.get<currentUserDetailsResponse>('current-user')

    protected _handleError = async (error: AxiosError) => {
        const config = error.config
        const router = useRouter()
        console.log(config)
        if (error.response) {
            const { status } = error.response
            switch (status) {
                // 500/404/403
                case 401:
                    return Promise.reject(error)
                default:
                    router.push({ name: 'ErrorPage' })
                    return Promise.reject(error)
            }
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            return Promise.reject(error)
        } else {
            // Something happened in setting up the request that triggered an Error
            return Promise.reject(error)
        }
    }
    protected _handleRequest = (config: InternalAxiosRequestConfig) => {
        config.headers.Authorization = `Bearer ${store.state.auth.accessToken}`
        return config
    }
}


const authApi: AuthApi = new AuthApi()

export default authApi
