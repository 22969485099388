import { createRouter, createWebHistory } from 'vue-router'
const routes = [
    {
        path: '/',
        name: 'VoiceOverGenerator',
        component: () => import(/* webpackChunkName: "Dashboard" */ '@/views/VoiceOverGenerator.vue')
    },
    {
        path: '/error',
        name: 'ErrorPage',
        component: () => import(/* webpackChunkName: "ErrorPage" */ '@/views/ErrorPage.vue'),
    },
    {
        path: '/:catchAll(.*)',
        component: () => import(/* webpackChunkName: "NotFoundPage" */ '@/components/NotFoundPage.vue'),
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

export default router
